<template>
  <span v-if="!is_change" class="body-2" style="color: green">
    <v-icon color="green" small class="mr-2"
      >mdi-content-save-check-outline</v-icon
    >версия сохранена
    <span v-if="is_completed">и отправлена на сравнение</span></span
  ><span v-else
    ><v-btn
      small
      text
      :loading="saving"
      :disabled="is_lock"
      @click="onSave"
      class="ma-0"
      ><v-icon color="gray" small class="mr-2">{{
        is_lock ? 'mdi-lock' : 'mdi-content-save-alert-outline'
      }}</v-icon
      >Сохранить</v-btn
    ></span
  >
</template>

<script>
export default {
  name: 'Info-Save-Btn',
  props: {
    is_change: {
      type: Boolean,
      default: false,
    },
    is_completed: {
      type: Boolean,
      default: false,
    },
    saving: {
      type: Boolean,
      default: false,
    },
    is_lock: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({}),
  methods: {
    onSave(event) {
      if (!this.is_lock) this.$emit('click', event)
    },
  },
}
</script>
