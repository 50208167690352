<template>
  <v-container v-if="versionListReady" v-bind="$attrs">
    <!-- затенение и крутилка на загрузку -->
    <v-overlay :value="creating" light class="text-center">
      <span v-if="creating">Создание экспертизы... </span>
      <br />
      <v-progress-circular indeterminate size="64" class="mt-3" />
    </v-overlay>
    <IntersectionDiv />
    <v-row dense class="sticky mb-5">
      <v-col>
        <v-container class="mb-0 pa-0">
          <v-row v-if="!last" dense class="mt-3">
            <v-col md="6">
              <VerExpSelect
                label="Текущая версия"
                :value="subExpertiseUUID"
                :items="versionList"
                item-value="version_uuid"
                item-text="version_name"
                @change="uuid => changeVersion(uuid)"
                :disabled="edit_version_name || loadingExp"
                :readonly="change"
                :loading="loadingExp"
                :is_completed="is_completed"
                :is_checked="is_checked"
                :is_analog_completed="is_analog_completed"
              />
            </v-col>
            <v-col md="2" v-if="!loadingExp && !readonly">
              <div v-if="canEdit">
                <v-btn
                  v-if="!hasTitleGone"
                  icon
                  @click="edit_version_name = !edit_version_name"
                  :color="edit_version_name ? 'primary' : null"
                  title="Изменить название версии"
                  ><v-icon small>mdi-pencil</v-icon></v-btn
                >
              </div>
              <div v-else class="text-right">
                <v-btn
                  v-if="!hasTitleGone"
                  :disabled="!canCopy"
                  @click="copyVersion"
                  class="mr-4"
                  small
                  title="Скопировать версию для редактирования"
                  ><v-icon small class="mr-2">mdi-content-copy</v-icon>
                  Копировать версию</v-btn
                >
              </div>
            </v-col>
            <!-- статус -->
            <v-col md="4" v-if="canEdit && !loadingExp" class="text-right"
              ><InfoSaveBtn
                :is_change="change"
                :is_completed="is_completed"
                :is_lock="is_lock"
                :saving="savingExp"
                @click="save"
              />
            </v-col>
          </v-row>
          <!-- Строка смены названия версии -->
          <v-row
            v-if="!last && canEdit && edit_version_name && !hasTitleGone"
            dense
            class="mt-4"
          >
            <v-col>
              <v-text-field
                label="Новое название версии"
                dense
                autofocus
                :value="subExpertiseName"
                @input="version_name_change"
                @blur="onBlur"
                :readonly="subExpertiseIsReadOnly"
                :error="!version_name"
                hint="Укажите понятное название для текущей версии или оставьте значение по-умолчанию"
              ></v-text-field>
            </v-col>
          </v-row>
          <!-- строка Инфо -->
          <v-row
            v-if="showInfo && !loadingExp"
            dense
            class="mt-0 caption font-weight-light"
          >
            <v-col class="text-left" cols="3"
              ><span v-if="change" class="info_change"
                >* есть изменения<span v-if="is_lock"
                  >, сохранение заблокировано!</span
                ></span
              ><span v-if="last && !change" class="body-2" style="color: green"
                ><v-icon color="green" small class="mr-2"
                  >mdi-content-save-check-outline</v-icon
                >Сохранено <span v-if="is_completed">и отправлено</span></span
              >
            </v-col>
            <v-col class="text-right"
              ><span>Создана: {{ createTime }}</span
              ><span>, изменена: {{ updateTime }}</span>
              <span> - {{ author | shortFIO }}</span>
              <span v-if="is_readonly"
                >,
                <span class="red--text text--darken-3 font-weight-regular"
                  >версия только для чтения</span
                >.</span
              >
            </v-col></v-row
          >
        </v-container>
      </v-col>
    </v-row>
    <!-- Слот для содержимого -->
    <v-row dense>
      <v-col>
        <slot
          v-if="versionListReady && !loadingExp && !myloadingPlpData"
          name="content"
          :data="tmpExpData"
          :readonly="is_readonly"
          :onChange="onChange"
          :onBlur="onBlur"
          :onCreate="!is_completed ? onCreate : null"
          :saved="!change"
        />
      </v-col>
    </v-row>
    <!----------------------->

    <!-- Разделительная линия, внизу -->
    <v-row v-if="!hideLine && canEdit && !loadingExp" dense>
      <v-col><hr class="secondary" /></v-col>
    </v-row>
    <!-- строка Инфо, внизу -->
    <v-row
      v-if="showInfo && !loadingExp && canEdit"
      dense
      class="mt-0 caption font-weight-light text-right"
    >
      <v-col md="4" class="text-left">
        <span v-if="last && !change" class="body-2" style="color: green"
          ><v-icon color="green" small class="mr-2"
            >mdi-content-save-check-outline</v-icon
          >Сохранено <span v-if="is_completed">и отправлено</span></span
        >
        <InfoSaveBtn
          v-if="!last && canEdit"
          :is_change="change"
          :is_completed="is_completed"
          :is_lock="is_lock"
          :saving="savingExp"
          @click="save"
        />
      </v-col>
      <v-col md="8"
        ><span>Создана: {{ createTime }}</span
        ><span>, изменена: {{ updateTime }}</span>
        <span v-if="is_readonly">, версия только для чтения.</span>
      </v-col>
    </v-row>
    <!-- Слот кнопки снизу "bottom" -->
    <v-row v-if="!hideSendBtn && !loadingExp">
      <!-- слоты внизу - до -->
      <v-col cols="4">
        <slot
          name="prepend-bottom"
          :submit="submit"
          :saved="!change"
          :readonly="!canEdit"
          :is_completed="is_completed"
          :is_analog_completed="is_analog_completed"
          :onChange="onChange"
          :onBlur="onBlur"
          :data="tmpExpData"
        ></slot>
      </v-col>
      <!-- слоты внизу - по центру -->
      <v-col class="text-right">
        <slot
          name="bottom"
          :submit="submit"
          :saved="!change"
          :readonly="!canEdit"
          :is_completed="is_completed"
          :is_analog_completed="is_analog_completed"
          :onChange="onChange"
          :onBlur="onBlur"
          :data="tmpExpData"
        >
          <v-btn
            v-if="next_route"
            :to="{ name: next_route.name }"
            :title="next_route.title"
            color="accent"
            >{{ next_route.label }}<v-icon right>mdi-chevron-right</v-icon>
          </v-btn>
          <btn-with-confirm
            v-else
            color="primary"
            iconMain="mdi-check-bold"
            @click="submit"
            :disabled="is_completed"
            title="Отправить всю экспертизу на сравнение"
            left
            >Отправить на сравнение</btn-with-confirm
          >
        </slot>
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped>
.info_change {
  color: chocolate;
  font-weight: 400;
}
.sticky {
  position: sticky;
  position: -webkit-sticky;
  top: 48px;
  display: flex;
  /*justify-content: center;*/
  align-items: center;
  z-index: 3;
  background-color: white;
  border-bottom: 2px solid #424242;
}
</style>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { copyObject } from '@/lib/objects'
import InfoSaveBtn from '@/components/buttons/InfoSaveBtn'
import btnWithConfirm from '@/components/buttons/btnWithConfirm'
import IntersectionDiv from '@/components/overlay/IntersectionDiv'
import VerExpSelect from '@/components/editors/VerExpSelect'

const SAVE_TIMEOUT = 5000
const SAVE_TIMEOUT_FORCE = 100
const SAVE_TIMEOUT_RETRY = 1000

export default {
  name: 'Version-Select',
  components: { VerExpSelect, InfoSaveBtn, btnWithConfirm, IntersectionDiv },

  props: {
    hideInfo: {
      type: Boolean,
      default: false,
    },
    hideLine: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    // требуется предзагрузка plpData
    needPlpData: {
      type: Boolean,
      default: false,
    },
    // Спрятать кнопку "отправить"
    hideSendBtn: {
      type: Boolean,
      default: false,
    },
    // Режим последней версии без возможности её смены
    last: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    interval: null,
    creating: false,
    change: false,
    lock: false,
    tmpExpData: null,
    myloadingPlpData: false,
    version_name: null,
    edit_version_name: false,
  }),
  computed: {
    ...mapGetters([
      'hasTitleGone',
      //
      'versionList',
      'versionListReady',
      'storedPlpDataReady',
      'plp_task',
      'subExpertise',
      'subExpertiseUUID',
      'subExpertiseName',
      'subExpertiseData',
      'subExpertiseIsMainVersion',
      'subExpertiseIsMerged',
      'subExpertiseIsReadOnly',
      'loadingExp',
      'savingExp',
    ]),
    canEdit() {
      return (
        !this.readonly &&
        !this.subExpertiseIsReadOnly &&
        !this.subExpertiseIsMerged &&
        this.subExpertiseIsMainVersion &&
        !this.is_completed
      )
    },
    is_readonly() {
      return !this.canEdit || (this.is_analog && this.is_analog_completed)
    },
    canCopy() {
      return this.subExpertiseUUID && !this.canEdit && !this.is_completed
    },
    need_create() {
      return this.versionListReady && !this.versionList?.length
    },
    is_analog() {
      return this.$route.meta?.is_analog ?? false
    },
    is_analog_completed() {
      return this.is_analog && this.plp_task?.is_analog_completed
    },
    is_completed() {
      return this.plp_task?.is_completed ?? false
    },
    is_checked() {
      return this.plp_task?.is_checked ?? false
    },
    createTime() {
      if (this.subExpertise?.time_create) {
        return new Date(this.subExpertise.time_create).toLocaleString()
      } else return ''
    },
    updateTime() {
      if (this.subExpertise?.time_edit) {
        return new Date(this.subExpertise.time_edit).toLocaleString()
      } else return ''
    },
    author() {
      return this.subExpertise?.editor?.fio
    },
    showInfo() {
      const time_ready =
        this.subExpertise?.time_edit && this.subExpertise?.time_create
      return !this.hideInfo && time_ready
    },
    is_lock() {
      return this.lock || !this.version_name
    },
    next_route() {
      return this.$route.meta?.next ?? null
    },
  },
  watch: {
    versionListReady: 'initVersionList',
    storedPlpDataReady: 'initPlpDataReady',
    subExpertiseData: 'initExpertise',
  },
  created() {
    if (this.needPlpData) this.GET_PLP_DATA()
    this.initVersionList()
    this.change = false
    this.initExpertise()
  },
  destroyed() {
    if (this.interval) {
      clearTimeout(this.interval)
    }
    this.interval = -1
  },
  methods: {
    ...mapActions([
      'GET_PLP_DATA',
      'COPY_EXPERTISE',
      'CREATE_EXPERTISE',
      'SET_EXPERTISE_VERSION',
      'SAVE_EXPERTISE',
      'UPDATE_PLP_TASK',
    ]),
    ...mapMutations(['setHasUnsavedChanges']),
    initVersionList() {
      if (this.need_create && !this.readonly && !this.storedPlpDataReady) {
        this.myloadingPlpData = true
        this.GET_PLP_DATA()
      }
    },
    initExpertise() {
      //console.log('init - subExpertiseData', this.subExpertiseUUID)
      this.version_name = this.subExpertiseName
      if (!this.change) {
        console.log('ReInit')
        this.tmpExpData = copyObject(this.subExpertiseData)
      }
    },
    initPlpDataReady() {
      if (this.initPlpDataReady && this.myloadingPlpData)
        this.myloadingPlpData = false
    },
    // меняем версию
    async changeVersion(uuid) {
      this.edit_version_name = false
      await this.save()
      await this.SET_EXPERTISE_VERSION({ uuid })
      this.$emit('change_version')
    },
    async copyVersion() {
      await this.COPY_EXPERTISE()
      this.edit_version_name = true
      this.$emit('copy_version_click')
    },
    version_name_change(val) {
      this.version_name = val.trim()
      this.onChange(null, false, this.lock)
    },

    // создаём новую экспертизу
    async onCreate(data) {
      if (!this.readonly && this.need_create && data && !this.creating)
        try {
          this.creating = true
          await this.CREATE_EXPERTISE(data)
        } finally {
          this.creating = false
        }
      return copyObject(this.subExpertiseData)
    },

    // запускаем таймер для сохранения
    onChange(data, force = false, lock = false) {
      if (this.subExpertiseIsReadOnly) return
      // всегда последняя версия тут
      if (this.interval) clearTimeout(this.interval)
      this.change = true
      this.lock = !!lock
      if (data) this.tmpExpData = data
      this.setHasUnsavedChanges(true)

      this.interval = setTimeout(
        this.save,
        force ? SAVE_TIMEOUT_FORCE : SAVE_TIMEOUT
      )
    },
    // уходим с контрола дёрнуть проверку на изменения и сохранение
    onBlur() {
      this.edit_version_name = false
      this.save()
    },

    async submit() {
      // сохраняем если нужно и устанавливаем "отправку на сравнение"
      await this.save()
      await this.setCompleted(1)
      this.$toast.success('Отправлено на сравнение успешно!', {
        showCloseButtonOnHover: true,
        timeout: 5000,
        icon: true,
      })
    },

    // фактическое сохраннение данных
    async save() {
      if (!this.change) {
        clearTimeout(this.interval)
        this.interval = null
        return
      }
      try {
        if (this.is_lock || this.savingExp)
          throw new Error('Retry, saving is locked')

        clearTimeout(this.interval)
        this.interval = null

        // сохраняем
        await this.SAVE_EXPERTISE({
          data: this.tmpExpData,
          version_name: this.version_name,
        })
        if (!this.interval) {
          this.change = false
          this.setHasUnsavedChanges(this.change)
        } else throw new Error('Retry, saving')
      } catch (err) {
        let retry_force = 1
        if (err.code === 409) {
          this.$toast.warning(err.message, {
            showCloseButtonOnHover: true,
            timeout: SAVE_TIMEOUT,
            icon: true,
          })
          console.log('Кто то нас опередил, ещё попытка!')
          retry_force = 0
        }
        // если удаляемся или код 401-неавторизован
        if (this.interval === -1 || err.code === 401) return
        // вторая попытка
        console.log('- ещё попытка !')
        this.interval = setTimeout(
          this.save,
          retry_force ? SAVE_TIMEOUT_RETRY : SAVE_TIMEOUT
        )
      }
    },

    async setCompleted(val) {
      await this.UPDATE_PLP_TASK({
        is_completed: !!val,
      })
    },
  },
}
</script>
