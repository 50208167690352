<template>
  <v-select
    v-bind="$attrs"
    v-on="$listeners"
    :readonly="readonly"
    :loading="loading"
    class="mr-4"
    dense
    :hide-details="hideDetails"
    ><template #prepend
      ><v-icon
        v-if="is_checked"
        color="success"
        title="Экспертиза завершена и проверена"
        >mdi-flag-checkered</v-icon
      >
      <v-icon
        v-else-if="is_completed"
        color="success"
        title="Экспертиза завершена"
        >mdi-check</v-icon
      ><v-icon
        v-else-if="is_analog_completed"
        color="success"
        title="Аналоги готовы к сравнению"
        >mdi-clock-check-outline</v-icon
      ></template
    ></v-select
  >
</template>

<script>
export default {
  name: 'Ver-Exp-Select',
  inheritAttrs: false,
  props: {
    readonly: {
      type: Boolean,
      default: false,
    },
    hideDetails: {
      type: Boolean,
      default: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    is_checked: {
      type: Boolean,
      default: false,
    },
    is_completed: {
      type: Boolean,
      default: false,
    },
    is_analog_completed: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
